import ArrowDownLOutlined from './type=ArrowDown, size=l, weight=outlined.svg';
import ArrowDownMOutlined from './type=ArrowDown, size=m, weight=outlined.svg';
import ArrowDownSOutlined from './type=ArrowDown, size=s, weight=outlined.svg';
import ArrowLeftLOutlined from './type=ArrowLeft, size=l, weight=outlined.svg';
import ArrowLeftMOutlined from './type=ArrowLeft, size=m, weight=outlined.svg';
import ArrowLeftSOutlined from './type=ArrowLeft, size=s, weight=outlined.svg';
import ArrowRightLOutlined from './type=ArrowRight, size=l, weight=outlined.svg';
import ArrowRightMOutlined from './type=ArrowRight, size=m, weight=outlined.svg';
import ArrowRightSOutlined from './type=ArrowRight, size=s, weight=outlined.svg';
import ArrowUpLOutlined from './type=ArrowUp, size=l, weight=outlined.svg';
import ArrowUpMOutlined from './type=ArrowUp, size=m, weight=outlined.svg';
import ArrowUpSOutlined from './type=ArrowUp, size=s, weight=outlined.svg';
import BookBookmarkLOutlined from './type=BookBookmark, size=l, weight=outlined.svg';
import BookBookmarkMOutlined from './type=BookBookmark, size=m, weight=outlined.svg';
import BookBookmarkSOutlined from './type=BookBookmark, size=s, weight=outlined.svg';
import BookmarkSimpleLFilled from './type=BookmarkSimple, size=l, weight=filled.svg';
import BookmarkSimpleLOutlined from './type=BookmarkSimple, size=l, weight=outlined.svg';
import BookmarkSimpleMFilled from './type=BookmarkSimple, size=m, weight=filled.svg';
import BookmarkSimpleMOutlined from './type=BookmarkSimple, size=m, weight=outlined.svg';
import BookmarkSimpleSFilled from './type=BookmarkSimple, size=s, weight=filled.svg';
import BookmarkSimpleSOutlined from './type=BookmarkSimple, size=s, weight=outlined.svg';
import CaretDownLFilled from './type=CaretDown, size=l, weight=filled.svg';
import CaretDownLOutlined from './type=CaretDown, size=l, weight=outlined.svg';
import CaretDownMFilled from './type=CaretDown, size=m, weight=filled.svg';
import CaretDownMOutlined from './type=CaretDown, size=m, weight=outlined.svg';
import CaretDownSOutlined from './type=CaretDown, size=s, weight=outlined.svg';
import CaretDownXsOutlined from './type=CaretDown, size=xs, weight=outlined.svg';
import CaretDownFilledSFilled from './type=CaretDownFilled, size=s, weight=filled.svg';
import CaretLeftLOutlined from './type=CaretLeft, size=l, weight=outlined.svg';
import CaretLeftMOutlined from './type=CaretLeft, size=m, weight=outlined.svg';
import CaretLeftSOutlined from './type=CaretLeft, size=s, weight=outlined.svg';
import CaretLeftXsOutlined from './type=CaretLeft, size=xs, weight=outlined.svg';
import CaretRightLOutlined from './type=CaretRight, size=l, weight=outlined.svg';
import CaretRightMOutlined from './type=CaretRight, size=m, weight=outlined.svg';
import CaretRightSOutlined from './type=CaretRight, size=s, weight=outlined.svg';
import CaretRightXsOutlined from './type=CaretRight, size=xs, weight=outlined.svg';
import CaretUpLFilled from './type=CaretUp, size=l, weight=filled.svg';
import CaretUpLOutlined from './type=CaretUp, size=l, weight=outlined.svg';
import CaretUpMFilled from './type=CaretUp, size=m, weight=filled.svg';
import CaretUpMOutlined from './type=CaretUp, size=m, weight=outlined.svg';
import CaretUpSOutlined from './type=CaretUp, size=s, weight=outlined.svg';
import CaretUpXsOutlined from './type=CaretUp, size=xs, weight=outlined.svg';
import CaretUpFilledSFilled from './type=CaretUpFilled, size=s, weight=filled.svg';
import ChalkboardTeacherLOutlined from './type=ChalkboardTeacher, size=l, weight=outlined.svg';
import ChalkboardTeacherMOutlined from './type=ChalkboardTeacher, size=m, weight=outlined.svg';
import ChalkboardTeacherSOutlined from './type=ChalkboardTeacher, size=s, weight=outlined.svg';
import ChartPieSliceLFilled from './type=ChartPieSlice, size=l, weight=filled.svg';
import ChartPieSliceMFilled from './type=ChartPieSlice, size=m, weight=filled.svg';
import ChartPieSliceSFilled from './type=ChartPieSlice, size=s, weight=filled.svg';
import CheckMFilled from './type=Check, size=m, weight=filled.svg';
import CheckSFilled from './type=Check, size=s, weight=filled.svg';
import CheckCircleLFilled from './type=CheckCircle, size=l, weight=filled.svg';
import CheckCircleLOutlined from './type=CheckCircle, size=l, weight=outlined.svg';
import CheckCircleMFilled from './type=CheckCircle, size=m, weight=filled.svg';
import CheckCircleMOutlined from './type=CheckCircle, size=m, weight=outlined.svg';
import CheckCircleSFilled from './type=CheckCircle, size=s, weight=filled.svg';
import CheckCircleSOutlined from './type=CheckCircle, size=s, weight=outlined.svg';
import CheckFatLFilled from './type=CheckFat, size=l, weight=filled.svg';
import CheckFatMFilled from './type=CheckFat, size=m, weight=filled.svg';
import CheckFatSFilled from './type=CheckFat, size=s, weight=filled.svg';
import CheckSquareLFilled from './type=CheckSquare, size=l, weight=filled.svg';
import CheckSquareMFilled from './type=CheckSquare, size=m, weight=filled.svg';
import CheckSquareSFilled from './type=CheckSquare, size=s, weight=filled.svg';
import CircleLOutlined from './type=Circle, size=l, weight=outlined.svg';
import CircleMOutlined from './type=Circle, size=m, weight=outlined.svg';
import CircleSOutlined from './type=Circle, size=s, weight=outlined.svg';
import CopySimpleLOutlined from './type=CopySimple, size=l, weight=outlined.svg';
import CopySimpleMOutlined from './type=CopySimple, size=m, weight=outlined.svg';
import CopySimpleSOutlined from './type=CopySimple, size=s, weight=outlined.svg';
import CreditCardLOutlined from './type=CreditCard, size=l, weight=outlined.svg';
import CreditCardMOutlined from './type=CreditCard, size=m, weight=outlined.svg';
import CreditCardSOutlined from './type=CreditCard, size=s, weight=outlined.svg';
import CurrencyCircleDollarLOutlined from './type=CurrencyCircleDollar, size=l, weight=outlined.svg';
import CurrencyCircleDollarMOutlined from './type=CurrencyCircleDollar, size=m, weight=outlined.svg';
import CurrencyCircleDollarSOutlined from './type=CurrencyCircleDollar, size=s, weight=outlined.svg';
import DownloadSimpleLOutlined from './type=DownloadSimple, size=l, weight=outlined.svg';
import DownloadSimpleMOutlined from './type=DownloadSimple, size=m, weight=outlined.svg';
import DownloadSimpleSOutlined from './type=DownloadSimple, size=s, weight=outlined.svg';
import EnvelopeSimpleLOutlined from './type=EnvelopeSimple, size=l, weight=outlined.svg';
import EnvelopeSimpleMOutlined from './type=EnvelopeSimple, size=m, weight=outlined.svg';
import EnvelopeSimpleSOutlined from './type=EnvelopeSimple, size=s, weight=outlined.svg';
import EyeLFilled from './type=Eye, size=l, weight=filled.svg';
import EyeMFilled from './type=Eye, size=m, weight=filled.svg';
import EyeSFilled from './type=Eye, size=s, weight=filled.svg';
import EyeSlashLOutlined from './type=EyeSlash, size=l, weight=outlined.svg';
import EyeSlashMOutlined from './type=EyeSlash, size=m, weight=outlined.svg';
import EyeSlashSOutlined from './type=EyeSlash, size=s, weight=outlined.svg';
import FilePdfLOutlined from './type=FilePdf, size=l, weight=outlined.svg';
import FilePdfMOutlined from './type=FilePdf, size=m, weight=outlined.svg';
import FilePdfSOutlined from './type=FilePdf, size=s, weight=outlined.svg';
import GoogleLFilled from './type=google, size=l, weight=filled.svg';
import GoogleMFilled from './type=google, size=m, weight=filled.svg';
import GoogleSFilled from './type=google, size=s, weight=filled.svg';
import HouseLOutlined from './type=House, size=l, weight=outlined.svg';
import HouseMOutlined from './type=House, size=m, weight=outlined.svg';
import HouseSOutlined from './type=House, size=s, weight=outlined.svg';
import InfoLOutlined from './type=Info, size=l, weight=outlined.svg';
import InfoMOutlined from './type=Info, size=m, weight=outlined.svg';
import InfoSOutlined from './type=Info, size=s, weight=outlined.svg';
import LinkLOutlined from './type=Link, size=l, weight=outlined.svg';
import LinkMOutlined from './type=Link, size=m, weight=outlined.svg';
import LinkSOutlined from './type=Link, size=s, weight=outlined.svg';
import LockKeyLFilled from './type=LockKey, size=l, weight=filled.svg';
import LockKeyMFilled from './type=LockKey, size=m, weight=filled.svg';
import LockKeySFilled from './type=LockKey, size=s, weight=filled.svg';
import LockKeyXsFilled from './type=LockKey, size=xs, weight=filled.svg';
import MagnifyingGlassLOutlined from './type=MagnifyingGlass, size=l, weight=outlined.svg';
import MagnifyingGlassMOutlined from './type=MagnifyingGlass, size=m, weight=outlined.svg';
import MagnifyingGlassSOutlined from './type=MagnifyingGlass, size=s, weight=outlined.svg';
import MinusLOutlined from './type=Minus, size=l, weight=outlined.svg';
import MinusMOutlined from './type=Minus, size=m, weight=outlined.svg';
import MinusSOutlined from './type=Minus, size=s, weight=outlined.svg';
import NumberFourLOutlined from './type=NumberFour, size=l, weight=outlined.svg';
import NumberFourMOutlined from './type=NumberFour, size=m, weight=outlined.svg';
import NumberFourSOutlined from './type=NumberFour, size=s, weight=outlined.svg';
import NumberOneLOutlined from './type=NumberOne, size=l, weight=outlined.svg';
import NumberOneMOutlined from './type=NumberOne, size=m, weight=outlined.svg';
import NumberOneSOutlined from './type=NumberOne, size=s, weight=outlined.svg';
import NumberThreeLOutlined from './type=NumberThree, size=l, weight=outlined.svg';
import NumberThreeMOutlined from './type=NumberThree, size=m, weight=outlined.svg';
import NumberThreeSOutlined from './type=NumberThree, size=s, weight=outlined.svg';
import NumberTwoLOutlined from './type=NumberTwo, size=l, weight=outlined.svg';
import NumberTwoMOutlined from './type=NumberTwo, size=m, weight=outlined.svg';
import NumberTwoSOutlined from './type=NumberTwo, size=s, weight=outlined.svg';
import PauseCircleLFilled from './type=PauseCircle, size=l, weight=filled.svg';
import PauseCircleLOutlined from './type=PauseCircle, size=l, weight=outlined.svg';
import PauseCircleMFilled from './type=PauseCircle, size=m, weight=filled.svg';
import PauseCircleMOutlined from './type=PauseCircle, size=m, weight=outlined.svg';
import PauseCircleSFilled from './type=PauseCircle, size=s, weight=filled.svg';
import PauseCircleSOutlined from './type=PauseCircle, size=s, weight=outlined.svg';
import PaymentmethodiconLFilled from './type=Payment method icon, size=l, weight=filled.svg';
import PaymentmethodiconMFilled from './type=Payment method icon, size=m, weight=filled.svg';
import PaymentmethodiconSFilled from './type=Payment method icon, size=s, weight=filled.svg';
import PencilSimpleLFilled from './type=PencilSimple, size=l, weight=filled.svg';
import PencilSimpleMFilled from './type=PencilSimple, size=m, weight=filled.svg';
import PencilSimpleSFilled from './type=PencilSimple, size=s, weight=filled.svg';
import PencilSimpleXsFilled from './type=PencilSimple, size=xs, weight=filled.svg';
import PlayCircleLFilled from './type=PlayCircle, size=l, weight=filled.svg';
import PlayCircleLOutlined from './type=PlayCircle, size=l, weight=outlined.svg';
import PlayCircleMFilled from './type=PlayCircle, size=m, weight=filled.svg';
import PlayCircleMOutlined from './type=PlayCircle, size=m, weight=outlined.svg';
import PlayCircleSFilled from './type=PlayCircle, size=s, weight=filled.svg';
import PlayCircleSOutlined from './type=PlayCircle, size=s, weight=outlined.svg';
import PlusLOutlined from './type=Plus, size=l, weight=outlined.svg';
import PlusMOutlined from './type=Plus, size=m, weight=outlined.svg';
import PlusSOutlined from './type=Plus, size=s, weight=outlined.svg';
import QuestionLFilled from './type=Question, size=l, weight=filled.svg';
import QuestionLOutlined from './type=Question, size=l, weight=outlined.svg';
import QuestionMFilled from './type=Question, size=m, weight=filled.svg';
import QuestionMOutlined from './type=Question, size=m, weight=outlined.svg';
import QuestionSFilled from './type=Question, size=s, weight=filled.svg';
import QuestionSOutlined from './type=Question, size=s, weight=outlined.svg';
import QuestionXsOutlined from './type=Question, size=xs, weight=outlined.svg';
import RecordLFilled from './type=Record, size=l, weight=filled.svg';
import RecordMFilled from './type=Record, size=m, weight=filled.svg';
import RecordSFilled from './type=Record, size=s, weight=filled.svg';
import SignOutLOutlined from './type=SignOut, size=l, weight=outlined.svg';
import SignOutMOutlined from './type=SignOut, size=m, weight=outlined.svg';
import SignOutSOutlined from './type=SignOut, size=s, weight=outlined.svg';
import SirenLFilled from './type=Siren, size=l, weight=filled.svg';
import SirenMFilled from './type=Siren, size=m, weight=filled.svg';
import SirenSFilled from './type=Siren, size=s, weight=filled.svg';
import SmileyLFilled from './type=Smiley, size=l, weight=filled.svg';
import SmileyLOutlined from './type=Smiley, size=l, weight=outlined.svg';
import SmileyMFilled from './type=Smiley, size=m, weight=filled.svg';
import SmileyMOutlined from './type=Smiley, size=m, weight=outlined.svg';
import SmileySFilled from './type=Smiley, size=s, weight=filled.svg';
import SmileySOutlined from './type=Smiley, size=s, weight=outlined.svg';
import SmileyMehLFilled from './type=SmileyMeh, size=l, weight=filled.svg';
import SmileyMehLOutlined from './type=SmileyMeh, size=l, weight=outlined.svg';
import SmileyMehMFilled from './type=SmileyMeh, size=m, weight=filled.svg';
import SmileyMehMOutlined from './type=SmileyMeh, size=m, weight=outlined.svg';
import SmileyMehSFilled from './type=SmileyMeh, size=s, weight=filled.svg';
import SmileyMehSOutlined from './type=SmileyMeh, size=s, weight=outlined.svg';
import SmileySadLFilled from './type=SmileySad, size=l, weight=filled.svg';
import SmileySadLOutlined from './type=SmileySad, size=l, weight=outlined.svg';
import SmileySadMFilled from './type=SmileySad, size=m, weight=filled.svg';
import SmileySadMOutlined from './type=SmileySad, size=m, weight=outlined.svg';
import SmileySadSFilled from './type=SmileySad, size=s, weight=filled.svg';
import SmileySadSOutlined from './type=SmileySad, size=s, weight=outlined.svg';
import SquareLOutlined from './type=Square, size=l, weight=outlined.svg';
import SquareMOutlined from './type=Square, size=m, weight=outlined.svg';
import SquareSOutlined from './type=Square, size=s, weight=outlined.svg';
import StopCircleLFilled from './type=StopCircle, size=l, weight=filled.svg';
import StopCircleLOutlined from './type=StopCircle, size=l, weight=outlined.svg';
import StopCircleMFilled from './type=StopCircle, size=m, weight=filled.svg';
import StopCircleMOutlined from './type=StopCircle, size=m, weight=outlined.svg';
import StopCircleSFilled from './type=StopCircle, size=s, weight=filled.svg';
import StopCircleSOutlined from './type=StopCircle, size=s, weight=outlined.svg';
import StudentLOutlined from './type=Student, size=l, weight=outlined.svg';
import StudentMOutlined from './type=Student, size=m, weight=outlined.svg';
import StudentSOutlined from './type=Student, size=s, weight=outlined.svg';
import TimerLFilled from './type=Timer, size=l, weight=filled.svg';
import TimerLOutlined from './type=Timer, size=l, weight=outlined.svg';
import TimerMFilled from './type=Timer, size=m, weight=filled.svg';
import TimerMOutlined from './type=Timer, size=m, weight=outlined.svg';
import TimerSFilled from './type=Timer, size=s, weight=filled.svg';
import TimerSOutlined from './type=Timer, size=s, weight=outlined.svg';
import UserCircleLOutlined from './type=UserCircle, size=l, weight=outlined.svg';
import UserCircleMOutlined from './type=UserCircle, size=m, weight=outlined.svg';
import UserCircleSOutlined from './type=UserCircle, size=s, weight=outlined.svg';
import UserCircleXsOutlined from './type=UserCircle, size=xs, weight=outlined.svg';
import WarningCircleLFilled from './type=WarningCircle, size=l, weight=filled.svg';
import WarningCircleLOutlined from './type=WarningCircle, size=l, weight=outlined.svg';
import WarningCircleMFilled from './type=WarningCircle, size=m, weight=filled.svg';
import WarningCircleMOutlined from './type=WarningCircle, size=m, weight=outlined.svg';
import WarningCircleSFilled from './type=WarningCircle, size=s, weight=filled.svg';
import WarningCircleSOutlined from './type=WarningCircle, size=s, weight=outlined.svg';
import XLOutlined from './type=X, size=l, weight=outlined.svg';
import XMOutlined from './type=X, size=m, weight=outlined.svg';
import XSOutlined from './type=X, size=s, weight=outlined.svg';
import XCircleLFilled from './type=XCircle, size=l, weight=filled.svg';
import XCircleMFilled from './type=XCircle, size=m, weight=filled.svg';
import XCircleSFilled from './type=XCircle, size=s, weight=filled.svg';

export {
  ArrowDownLOutlined,
  ArrowDownMOutlined,
  ArrowDownSOutlined,
  ArrowLeftLOutlined,
  ArrowLeftMOutlined,
  ArrowLeftSOutlined,
  ArrowRightLOutlined,
  ArrowRightMOutlined,
  ArrowRightSOutlined,
  ArrowUpLOutlined,
  ArrowUpMOutlined,
  ArrowUpSOutlined,
  BookBookmarkLOutlined,
  BookBookmarkMOutlined,
  BookBookmarkSOutlined,
  BookmarkSimpleLFilled,
  BookmarkSimpleLOutlined,
  BookmarkSimpleMFilled,
  BookmarkSimpleMOutlined,
  BookmarkSimpleSFilled,
  BookmarkSimpleSOutlined,
  CaretDownFilledSFilled,
  CaretDownLFilled,
  CaretDownLOutlined,
  CaretDownMFilled,
  CaretDownMOutlined,
  CaretDownSOutlined,
  CaretDownXsOutlined,
  CaretLeftLOutlined,
  CaretLeftMOutlined,
  CaretLeftSOutlined,
  CaretLeftXsOutlined,
  CaretRightLOutlined,
  CaretRightMOutlined,
  CaretRightSOutlined,
  CaretRightXsOutlined,
  CaretUpFilledSFilled,
  CaretUpLFilled,
  CaretUpLOutlined,
  CaretUpMFilled,
  CaretUpMOutlined,
  CaretUpSOutlined,
  CaretUpXsOutlined,
  ChalkboardTeacherLOutlined,
  ChalkboardTeacherMOutlined,
  ChalkboardTeacherSOutlined,
  ChartPieSliceLFilled,
  ChartPieSliceMFilled,
  ChartPieSliceSFilled,
  CheckCircleLFilled,
  CheckCircleLOutlined,
  CheckCircleMFilled,
  CheckCircleMOutlined,
  CheckCircleSFilled,
  CheckCircleSOutlined,
  CheckFatLFilled,
  CheckFatMFilled,
  CheckFatSFilled,
  CheckMFilled,
  CheckSFilled,
  CheckSquareLFilled,
  CheckSquareMFilled,
  CheckSquareSFilled,
  CircleLOutlined,
  CircleMOutlined,
  CircleSOutlined,
  CopySimpleLOutlined,
  CopySimpleMOutlined,
  CopySimpleSOutlined,
  CreditCardLOutlined,
  CreditCardMOutlined,
  CreditCardSOutlined,
  CurrencyCircleDollarLOutlined,
  CurrencyCircleDollarMOutlined,
  CurrencyCircleDollarSOutlined,
  DownloadSimpleLOutlined,
  DownloadSimpleMOutlined,
  DownloadSimpleSOutlined,
  EnvelopeSimpleLOutlined,
  EnvelopeSimpleMOutlined,
  EnvelopeSimpleSOutlined,
  EyeLFilled,
  EyeMFilled,
  EyeSFilled,
  EyeSlashLOutlined,
  EyeSlashMOutlined,
  EyeSlashSOutlined,
  FilePdfLOutlined,
  FilePdfMOutlined,
  FilePdfSOutlined,
  GoogleLFilled,
  GoogleMFilled,
  GoogleSFilled,
  HouseLOutlined,
  HouseMOutlined,
  HouseSOutlined,
  InfoLOutlined,
  InfoMOutlined,
  InfoSOutlined,
  LinkLOutlined,
  LinkMOutlined,
  LinkSOutlined,
  LockKeyLFilled,
  LockKeyMFilled,
  LockKeySFilled,
  LockKeyXsFilled,
  MagnifyingGlassLOutlined,
  MagnifyingGlassMOutlined,
  MagnifyingGlassSOutlined,
  MinusLOutlined,
  MinusMOutlined,
  MinusSOutlined,
  NumberFourLOutlined,
  NumberFourMOutlined,
  NumberFourSOutlined,
  NumberOneLOutlined,
  NumberOneMOutlined,
  NumberOneSOutlined,
  NumberThreeLOutlined,
  NumberThreeMOutlined,
  NumberThreeSOutlined,
  NumberTwoLOutlined,
  NumberTwoMOutlined,
  NumberTwoSOutlined,
  PauseCircleLFilled,
  PauseCircleLOutlined,
  PauseCircleMFilled,
  PauseCircleMOutlined,
  PauseCircleSFilled,
  PauseCircleSOutlined,
  PaymentmethodiconLFilled,
  PaymentmethodiconMFilled,
  PaymentmethodiconSFilled,
  PencilSimpleLFilled,
  PencilSimpleMFilled,
  PencilSimpleSFilled,
  PencilSimpleXsFilled,
  PlayCircleLFilled,
  PlayCircleLOutlined,
  PlayCircleMFilled,
  PlayCircleMOutlined,
  PlayCircleSFilled,
  PlayCircleSOutlined,
  PlusLOutlined,
  PlusMOutlined,
  PlusSOutlined,
  QuestionLFilled,
  QuestionLOutlined,
  QuestionMFilled,
  QuestionMOutlined,
  QuestionSFilled,
  QuestionSOutlined,
  QuestionXsOutlined,
  RecordLFilled,
  RecordMFilled,
  RecordSFilled,
  SignOutLOutlined,
  SignOutMOutlined,
  SignOutSOutlined,
  SirenLFilled,
  SirenMFilled,
  SirenSFilled,
  SmileyLFilled,
  SmileyLOutlined,
  SmileyMehLFilled,
  SmileyMehLOutlined,
  SmileyMehMFilled,
  SmileyMehMOutlined,
  SmileyMehSFilled,
  SmileyMehSOutlined,
  SmileyMFilled,
  SmileyMOutlined,
  SmileySadLFilled,
  SmileySadLOutlined,
  SmileySadMFilled,
  SmileySadMOutlined,
  SmileySadSFilled,
  SmileySadSOutlined,
  SmileySFilled,
  SmileySOutlined,
  SquareLOutlined,
  SquareMOutlined,
  SquareSOutlined,
  StopCircleLFilled,
  StopCircleLOutlined,
  StopCircleMFilled,
  StopCircleMOutlined,
  StopCircleSFilled,
  StopCircleSOutlined,
  StudentLOutlined,
  StudentMOutlined,
  StudentSOutlined,
  TimerLFilled,
  TimerLOutlined,
  TimerMFilled,
  TimerMOutlined,
  TimerSFilled,
  TimerSOutlined,
  UserCircleLOutlined,
  UserCircleMOutlined,
  UserCircleSOutlined,
  UserCircleXsOutlined,
  WarningCircleLFilled,
  WarningCircleLOutlined,
  WarningCircleMFilled,
  WarningCircleMOutlined,
  WarningCircleSFilled,
  WarningCircleSOutlined,
  XCircleLFilled,
  XCircleMFilled,
  XCircleSFilled,
  XLOutlined,
  XMOutlined,
  XSOutlined,
};
